import { CoreMenu } from "@core/types";

export const menu: CoreMenu[] = [
  {
    id: "home",
    title: "Home",
    translate: "MENU.HOME",
    type: "item",
    icon: "home",
    url: "home",
    
  },
  {
    id: "role",
    title: "Role",
    translate: "MENU.ROLE",
    type: "item",
    icon: "shield",
    url: "role",
    role:["Admin","FormAndRoleEditor","UserAndRoleEditor","RoleEditor"]
  },
  {
    id: "user",
    title: "User",
    translate: "MENU.USER",
    type: "item",
    icon: "users",
    url: "user",
    role:["Admin","UserAndRoleEditor","FormAndUserEditor","UserEditor"]

  },
  {
    id: "form",
    title: "Form",
    translate: "MENU.FORM",
    type: "item",
    icon: "link",
    url: "form",
    role:["Admin","FormAndUserEditor","FormAndUserEditor","UserEditor"]
  },
  {
    id: "sheet",
    title: "Sheet",
    translate: "MENU.SHEET",
    type: "item",
    icon: "link",
    url: "sheet",
    role:["Admin","FormAndUserEditor","FormAndUserEditor","UserEditor"]
  },
];
