import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DomSanitizer } from "@angular/platform-browser";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AuthenticationService } from "app/auth/service";
import { BackendServiceService } from "app/service/backend-service.service";
import { ToastrServiceService } from "app/service/toastrservice.service";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit {
  public formList: Array<any>;
  public tempFormList: any;
  public formCategoryList: Array<any>;
  public tempFormCategoryList: any;
  public sheetCategoryList: Array<any>;
  public tempSheetCategoryList: any;
  notificationForm: FormGroup;
  role = "default";
  notificationMessage: any;
  tempNotificationMessage: any;
  iframeUrl: any = "";
  constructor(private backendService: BackendServiceService, private _authService: AuthenticationService, private modalService: NgbModal, private fb: FormBuilder, private sanitizer: DomSanitizer, private toster: ToastrServiceService) {
    this.role = this._authService.currentUserValue.role;
    this.notificationForm = this.fb.group({
      title: ["", Validators.required],
      message: ["", Validators.required],
    });
  }

  public contentHeader: object;

  modalOpen(modalBasic, size?) {
    this.modalService.open(modalBasic, {
      centered: true,
      backdrop: "static",
      keyboard: true,
      size: size || "xl",
    });
  }

  collapseMenuControl(element,index){
    
  }

  modalCloseFn() {
    this.modalService.dismissAll();
  }

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit() {
    this.getHomeData();
    this.getNotificationData();
    this.contentHeader = {
      headerTitle: "Home",
      actionButton: true,
      breadcrumb: {
        type: "",
        links: [
          {
            name: "Home",
            isLink: true,
            link: "/",
          },
        ],
      },
    };
  }

  getHomeData() {
    this.backendService.getHomeData().subscribe((res) => {
      if (res.status) {
        this.formCategoryList = res.items.formCategoryList;
        this.tempFormCategoryList = this.formCategoryList;
        this.sheetCategoryList = res.items.sheetCategoryList;
        this.tempSheetCategoryList = this.sheetCategoryList;
      }
    });
  }
  getNotificationData() {
    this.backendService.getNotification().subscribe((res) => {
      if (res.status) {
        this.notificationMessage = res.items;
        this.tempNotificationMessage = this.notificationMessage;
      }
    });
  }

  editNotificationButtonClick(modalRef) {
    this.notificationForm.patchValue({
      title: this.notificationMessage.title,
      message: this.notificationMessage.message,
    });
    this.modalOpen(modalRef, "sm");
  }

  updateNotificationData(bodyData) {
    this.backendService.updateNotification(this.notificationMessage.notificationId, bodyData).subscribe((res) => {
      if (res.status) {
        this.getNotificationData();
        this.notificationForm.reset();
        this.modalService.dismissAll();
        this.toster.showSuccess(res.message, "Success");
      } else {
        this.toster.showError(res.message, "Error");
      }
    });
  }
  openLink(form) {
    let url = form.formLink;
    // Check if the URL doesn't start with "http://" or "https://"
    if (!url.startsWith("http://") && !url.startsWith("https://")) {
      // If not, prepend "https://"
      url = "https://" + url;
    }

    window.open(url, "_blank");
  }

  openLinkSheet(sheet) {
    let url = sheet.sheetLink;
    // Check if the URL doesn't start with "http://" or "https://"
    if (!url.startsWith("http://") && !url.startsWith("https://")) {
      // If not, prepend "https://"
      url = "https://" + url;
    }

    window.open(url, "_blank");
  }

  openIframe(modalRef, url) {
    if (!url.startsWith("http://") && !url.startsWith("https://")) {
      // If not, prepend "https://"
      url = "https://" + url;
    }

    this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    this.modalOpen(modalRef);
  }

  onFormSubmit() {
    this.updateNotificationData(this.notificationForm.value);
  }
}
