import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";
import { AuthenticationService } from "app/auth/service";

@Injectable({
  providedIn: "root",
})
export class BackendServiceService {
  private baseUrl = "https://server.fincooper.in";
  constructor(private http: HttpClient, private _authService: AuthenticationService) {}

  // token: any = JSON.parse(localStorage.getItem("authToken")).token;
  bodyData = JSON.parse(localStorage.getItem("user"));

  // Header = () => {
  //   let headers = new HttpHeaders();
  //   // headers = headers.append('content-type', 'multipart/form-data');
  //   // headers = headers.append('Accept', 'multipart/form-data');
  //   headers = headers.append("Authorization", `Bearer ${this.token}`);

  //   return { headers };
  // };

  // ----------------------------------------Home----------------------------------------//
  getHomeData(): Observable<any> {
    const httpOptions = { headers: new HttpHeaders({ Authorization: `Bearer ${this._authService.currentUserValue.token}` }) };
    return this.http.get<any>(this.baseUrl + "/v1/user/home/", httpOptions).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  getNotification(): Observable<any> {
    return this.http.get<any>(this.baseUrl + "/v1/user/notification/").pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  updateNotification(notificationId,bodyData): Observable<any> {
    return this.http.post<any>(this.baseUrl + "/v1/user/notification/update/"+notificationId,bodyData).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  // ----------------------------------------Role----------------------------------------//
  getRoleList(): Observable<any> {
    return this.http.get<any>(this.baseUrl + "/v1/role").pipe(
      map((data: any) => {
        return data;
      })
    );
  }
  addNewRole(bodyData): Observable<any> {
    return this.http.post<any>(this.baseUrl + "/v1/role/add", bodyData).pipe(
      map((data: any) => {
        return data;
      })
    );
  }
  updateNewRole(bodyData, selectedId): Observable<any> {
    return this.http.post<any>(this.baseUrl + "/v1/role/update/" + selectedId, bodyData).pipe(
      map((data: any) => {
        return data;
      })
    );
  }
  getRoleDetail(roleId): Observable<any> {
    return this.http.get<any>(this.baseUrl + "/v1/role/single/" + roleId).pipe(
      map((data: any) => {
        return data;
      })
    );
  }
  // ----------------------------------------User----------------------------------------//
  loginUser(bodyData): Observable<any> {
    return this.http.post<any>(this.baseUrl + "/v1/user/login", bodyData).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  getUserList(): Observable<any> {
    return this.http.get<any>(this.baseUrl + "/v1/user").pipe(
      map((data: any) => {
        return data;
      })
    );
  }
  addUser(bodyData): Observable<any> {
    return this.http.post<any>(this.baseUrl + "/v1/user/add", bodyData).pipe(
      map((data: any) => {
        return data;
      })
    );
  }
  updateUser(bodyData, selectedId): Observable<any> {
    return this.http.post<any>(this.baseUrl + "/v1/user/update/" + selectedId, bodyData).pipe(
      map((data: any) => {
        return data;
      })
    );
  }
  assignForm(bodyData, userId): Observable<any> {
    return this.http.post<any>(this.baseUrl + "/v1/user/assign/" + userId, bodyData).pipe(
      map((data: any) => {
        return data;
      })
    );
  }
  // ----------------------------------------Form----------------------------------------//
  addFormCategory(bodyData): Observable<any> {
    return this.http.post<any>(this.baseUrl + "/v1/form/category/add", bodyData).pipe(
      map((data: any) => {
        return data;
      })
    );
  }
  updateFormCategory(bodyData, formCategoryId): Observable<any> {
    return this.http.post<any>(this.baseUrl + "/v1/form/category/update/" + formCategoryId, bodyData).pipe(
      map((data: any) => {
        return data;
      })
    );
  }
  getFormCategoryList(): Observable<any> {
    return this.http.get<any>(this.baseUrl + "/v1/form/category").pipe(
      map((data: any) => {
        return data;
      })
    );
  }
  addForm(bodyData): Observable<any> {
    return this.http.post<any>(this.baseUrl + "/v1/form/add", bodyData).pipe(
      map((data: any) => {
        return data;
      })
    );
  }
  updateForm(bodyData, formId): Observable<any> {
    return this.http.post<any>(this.baseUrl + "/v1/form/update/" + formId, bodyData).pipe(
      map((data: any) => {
        return data;
      })
    );
  }
  getFormListOfCategory(): Observable<any> {
    return this.http.get<any>(this.baseUrl + "/v1/form/category").pipe(
      map((data: any) => {
        return data;
      })
    );
  }

 // ----------------------------------------sheet----------------------------------------//
 addSheetCategory(bodyData): Observable<any> {
  return this.http.post<any>(this.baseUrl + "/v1/sheet/category/add", bodyData).pipe(
    map((data: any) => {
      return data;
    })
  );
}
updateSheetCategory(bodyData, sheetCategoryId): Observable<any> {
  return this.http.post<any>(this.baseUrl + "/v1/sheet/category/update/" + sheetCategoryId, bodyData).pipe(
    map((data: any) => {
      return data;
    })
  );
}
getSheetCategoryList(): Observable<any> {
  return this.http.get<any>(this.baseUrl + "/v1/sheet/category").pipe(
    map((data: any) => {
      return data;
    })
  );
}
addSheet(bodyData): Observable<any> {
  return this.http.post<any>(this.baseUrl + "/v1/sheet/add", bodyData).pipe(
    map((data: any) => {
      return data;
    })
  );
}
updateSheet(bodyData, sheetId): Observable<any> {
  
  return this.http.post<any>(this.baseUrl + "/v1/sheet/update/" + sheetId, bodyData).pipe(
    map((data: any) => {
      return data;
    })
  );
}
getSheetListOfCategory(): Observable<any> {
  return this.http.get<any>(this.baseUrl + "/v1/sheet/category").pipe(
    map((data: any) => {
      return data;
    })
  );
}


}
