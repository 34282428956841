<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <div class="col-12">
      <div class="card card-congratulations">
        <div class="col-12 d-flex justify-content-end mt-1" *ngIf="role == 'Admin'">
          <a href="javascript:void(0)" class="d-flex align-items-center" (click)="editNotificationButtonClick(editNotification)">
            <i data-feather="edit" class="font-medium-1 text-white"></i>
          </a>
        </div>
        <div class="card-body text-center">
          <div class="avatar avatar-xl bg-primary shadow">
            <div class="avatar-content">
              <i data-feather="bell" class="font-large-1"></i>
            </div>
          </div>
          <div class="text-center">
            <h1 class="mb-1 text-white">{{ notificationMessage?.title || "No Notification" }}</h1>
            <h4 class="mb-1 text-white">{{ notificationMessage?.message || "" }}</h4>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <!-- Transaction card -->
      <div class="col-lg-4 col-md-6 col-12" *ngFor="let formCategory of tempFormCategoryList">
        <div class="card card-transaction">
          <div class="card-header">
            <h4 class="card-title">{{ formCategory.formCategoryName }}</h4>
            <button type="button" class="btn btn-outline-primary" [ngClass]="{'btn-outline-primary': !formCategory.isCollapsed,'btn-outline-danger': formCategory.isCollapsed}" (click)="formCategory.isCollapsed = !formCategory.isCollapsed" [attr.aria-expanded]="formCategory.isCollapsed" aria-controls="collapseExample4" rippleEffect>{{formCategory.isCollapsed?"Close":"Open"}}</button>
          </div>
          <div [ngbCollapse]="!formCategory.isCollapsed">
            <div class="card-body">
              <div class="transaction-item" *ngFor="let form of formCategory.formList">
                <div class="media">
                  <div class="avatar bg-light-primary rounded">
                    <div class="avatar-content">
                      <i data-feather="file-text" class="avatar-icon font-medium-3"></i>
                    </div>
                  </div>
                  <div class="media-body">
                    <h6 class="transaction-title">{{ form.formName }}</h6>
                    <small>{{ form.formDescription }}</small>
                  </div>
                </div>
                <button class="btn font-weight-bolder btn-primary" (click)="openIframe(iframeModal, form.formLink)">View</button>
              </div>
            </div>
           
          </div>

        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-12" *ngFor="let sheetCategory of tempSheetCategoryList">
        <div class="card card-transaction">
          <div class="card-header">
            <h4 class="card-title">{{ sheetCategory.sheetCategoryName }}</h4>
            <button type="button" class="btn btn-outline-primary" [ngClass]="{'btn-outline-primary': !sheetCategory.isCollapsed,'btn-outline-danger': sheetCategory.isCollapsed}" (click)="sheetCategory.isCollapsed = !sheetCategory.isCollapsed" [attr.aria-expanded]="sheetCategory.isCollapsed" aria-controls="collapseExample4" rippleEffect>{{sheetCategory.isCollapsed?"Close":"Open"}}</button>
            <!-- <i data-feather="more-vertical" class="font-medium-3 cursor-pointer"></i> -->
          </div>
          <div [ngbCollapse]="!sheetCategory.isCollapsed">
            <div class="card-body">
              <div class="transaction-item" *ngFor="let sheet of sheetCategory.sheetList">
                <div class="media">
                  <div class="avatar bg-light-primary rounded">
                    <div class="avatar-content">
                      <i data-feather="grid" class="avatar-icon font-medium-3"></i>
                    </div>
                  </div>
                  <div class="media-body">
                    <h6 class="transaction-title">{{ sheet.sheetName }}</h6>
                    <small>{{ sheet.sheetDescription }}</small>
                  </div>
                </div>
                <button class="btn font-weight-bolder btn-primary" (click)="openIframe(iframeModal, sheet.sheetLink)">View</button>
              </div>
            </div>
           
          </div>
        </div>
      </div>
      <!--/ Transaction card -->
    </div>
    
  </div>
</div>

<!-- add form category Modal -->
<ng-template #iframeModal let-modal>
  <div class="modal-header">
    <button type="button" class="close" (click)="modalCloseFn()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" tabindex="0" ngbAutofocus>
    <div class="content-wrapper container-xxl p-0">
      <div class="content-body">
        <iframe frameborder="0" allow="camera;" style="height: 500px; width: 100%; border: none" [src]="iframeUrl"></iframe>
      </div>
    </div>
  </div>
</ng-template>
<!-- / Modal -->

<!-- add form category Modal -->
<ng-template #editNotification let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="myModalLabel1">Change Notification</h4>
    <button type="button" class="close" (click)="modalCloseFn()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" tabindex="0" ngbAutofocus>
    <div class="content-wrapper container-xxl p-0">
      <div class="content-body">
        <form class="form" [formGroup]="notificationForm">
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label for="first-name-column">Title</label>
                <input type="text" id="first-name-column" formControlName="title" class="form-control" placeholder="Notification title" name="fname-column" />
              </div>
            </div>
            <div class="col-12">
              <div class="form-group">
                <label for="user-username">Message</label>
                <input type="text" id="user-username" formControlName="message" class="form-control" placeholder="Message" name="user-username" />
              </div>
            </div>
            <div class="col-12 d-flex flex-column">
              <button type="submit" (click)="onFormSubmit()" rippleEffect class="btn btn-primary mx-2 my-2">Submit</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-template>
<!-- / Modal -->
