import { Component, OnInit, TemplateRef, ViewChild, inject } from "@angular/core";
import { BackendServiceService } from "app/service/backend-service.service";
import { ColumnMode, DatatableComponent, SelectionType } from "@swimlane/ngx-datatable";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ToastrServiceService } from "app/service/toastrservice.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-user",
  templateUrl: "./user.component.html",
  styleUrls: ["./user.component.scss"],
})
export class UserComponent implements OnInit {
  public userList;
  public selected = [];
  public tempUserList: any;
  public SelectionType = SelectionType;
  public ColumnMode = ColumnMode;
  public kitchenSinkRows: any;
  private tempU = [];
  public exportCSVData = "";
  showBottomSheet: Boolean = false;
  userForm: FormGroup;
  isEditForm: Boolean = false;
  selectedUserData: any;
  filteredUserValue = "";

  public basicSelectedOption: number = 10;
  roleList: any;
  tempRoleList: any;
  addButtonIcon: string = this.showBottomSheet ? "x-circle" : "plus";
  formCategoryList: any;
  tempFormCategoryList: any;
  selectedFormData: any;

  sheetCategoryList: any;
  tempSheetCategoryList: any;
  selectedSheetData: any;

  @ViewChild(DatatableComponent) table: DatatableComponent;

  constructor(private backendService: BackendServiceService, private fb: FormBuilder, private modalService: NgbModal, private toster: ToastrServiceService) {
    this.userForm = this.fb.group({
      name: ["", Validators.required],
      userName: ["", Validators.required],
      password: [],
      role: ["", Validators.required],
      branch: ["", Validators.required],
      designation: ["", Validators.required],
      officialMail: ["", Validators.required],
      contact: ["", Validators.required],
      address: ["", Validators.required],
      employeeId: ["", Validators.required],
      joiningData: ["", Validators.required],
      department: ["", Validators.required],
    });
  }

  ngOnInit(): void {
    this.getUserList();
    this.getRoleList();
  }

  modalOpen(modalBasic, size) {
    this.getFormCategoryList();
    this.getSheetCategoryList();
    this.modalService.open(modalBasic, {
      centered: true,
      backdrop: "static",
      keyboard: true,
      size: size,
    });
  }

  modalCloseFn() {
    this.modalService.dismissAll();
  }

  addUser() {
    const bodyData = this.userForm.value;
    if (!bodyData.password) {
      this.toster.showError("password is required", "failed");
      return;
    }
    this.backendService.addUser(bodyData).subscribe((res) => {
      if (res.status) {
        this.showBottomSheet = false;
        this.getUserList();
        this.toster.showSuccess(res.message, "success");
        this.userForm.reset();
      } else {
        this.toster.showError(res.message, "failed");
      }
    });
  }

  assignFormToUser(bodyData) {
    this.backendService.assignForm(bodyData, this.selectedUserData.userId).subscribe((res) => {
      if (res.status) {
        this.getUserList();
        this.toster.showSuccess(res.message, "success");
      } else {
        this.toster.showError(res.message, "failed");
      }
    });
  }

  assignButtonClickForm(isAssignOperation, form) {
    const bodyData = isAssignOperation ? [...this.selectedUserData.assignedForm, form.formId] : this.selectedUserData.assignedForm.filter((formData) => formData != form.formId);
    if (isAssignOperation) {
      this.selectedUserData.assignedForm.push(form.formId);
    } else {
      this.selectedUserData.assignedForm = this.selectedUserData.assignedForm.filter((formData) => formData != form.formId);
    }
    this.assignFormToUser({ assignedForm: bodyData });
  }

  assignAllButtonClickForm() {
    const currentFormIdList = this.selectedFormData.formList.map((data) => data.formId);
    const userAssignedFormOfSelectedCategory = this.selectedUserData.assignedForm.filter((assignedForm) => currentFormIdList.includes(assignedForm));
    const userAssignedFormWithOutThisCategory =  this.selectedUserData.assignedForm.filter((assignedForm) => !currentFormIdList.includes(assignedForm));
    let  newList= userAssignedFormOfSelectedCategory.length===currentFormIdList.length?[]:currentFormIdList;
    this.selectedUserData.assignedForm  = [...newList,...userAssignedFormWithOutThisCategory]
    this.assignFormToUser({ assignedForm: this.selectedUserData.assignedForm });
  }

  assignAllButtonTextForm() {
    const currentFormIdList = this.selectedFormData.formList.map((data) => data.formId);
    const userAssignedFormOfSelectedCategory = this.selectedUserData.assignedForm.filter((assignedForm) => currentFormIdList.includes(assignedForm));
    return userAssignedFormOfSelectedCategory.length===currentFormIdList.length?"Remove all":"Assign all";
  }

  
  assignButtonClickSheet(isAssignOperation, sheet) {
    const bodyData = isAssignOperation ? [...this.selectedUserData.assignedSheet, sheet.sheetId] : this.selectedUserData.assignedSheet.filter((sheetData) => sheetData != sheet.sheetId);
    if (isAssignOperation) {
      this.selectedUserData.assignedSheet.push(sheet.sheetId);
    } else {
      this.selectedUserData.assignedSheet = this.selectedUserData.assignedSheet.filter((sheetData) => sheetData != sheet.sheetId);
    }
    this.assignFormToUser({ assignedSheet: bodyData });
  }
  
  assignAllButtonClickSheet() {
    const currentSheetIdList = this.selectedSheetData.sheetList.map((data) => data.sheetId);
    const userAssignedSheetOfSelectedCategory = this.selectedUserData.assignedSheet.filter((assignedSheet) => currentSheetIdList.includes(assignedSheet));
    const userAssignedSheetWithOutThisCategory =  this.selectedUserData.assignedSheet.filter((assignedSheet) => !currentSheetIdList.includes(assignedSheet));
    let  newList= userAssignedSheetOfSelectedCategory.length===currentSheetIdList.length?[]:currentSheetIdList;
    this.selectedUserData.assignedSheet  = [...newList,...userAssignedSheetWithOutThisCategory]
    this.assignFormToUser({ assignedSheet: this.selectedUserData.assignedSheet });

  }
  assignAllButtonTextSheet() {
    const currentSheetIdList = this.selectedSheetData.sheetList.map((data) => data.sheetId);
    const userAssignedSheetOfSelectedCategory = this.selectedUserData.assignedSheet.filter((assignedSheet) => currentSheetIdList.includes(assignedSheet));
    return userAssignedSheetOfSelectedCategory.length===currentSheetIdList.length?"Remove all":"Assign all";
  }
  
  editUser() {
    const bodyData = this.userForm.value;
    this.backendService.updateUser(bodyData, this.selectedUserData.userId).subscribe((res) => {
      if (res.status) {
        this.getUserList();
        this.toster.showSuccess(res.message, "success");
        this.toggleBottomSheet();
      } else {
        this.toster.showError(res.message, "failed");
      }
    });
  }

  getUserList() {
    this.backendService.getUserList().subscribe((res) => {
      this.userList = res.items;
      this.tempUserList = this.userList;
      this.kitchenSinkRows = this.userList;
      this.exportCSVData = this.userList.map((userData) => {
        return {
          name: userData.name,
          branch: userData.branch,
          designation: userData.designation,
          contact: userData.contact,
          address: userData.address,
          employeeId: userData.employeeId,
          joiningDate: userData.joiningData,
          department: userData.department,
        };
      });

      if (this.filteredUserValue && this.filteredUserValue != "") {
        this.filterUpdate();
      }
    });
  }

  getFormCategoryList() {
    this.backendService.getFormCategoryList().subscribe((res) => {
      this.formCategoryList = res.items;
      this.tempFormCategoryList = this.formCategoryList;
      this.selectedFormData = this.tempFormCategoryList[0];
    });
  }

  getSheetCategoryList() {
    this.backendService.getSheetCategoryList().subscribe((res) => {
      this.sheetCategoryList = res.items;
      this.tempSheetCategoryList = this.sheetCategoryList;
      this.selectedSheetData = this.tempSheetCategoryList[0];
    });
  }

  getRoleList() {
    this.backendService.getRoleList().subscribe((res) => {
      this.roleList = res.items;
      this.tempRoleList = this.roleList;
    });
  }

  onSelect({ selected }) {
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
  }
  onActivate(event) {}

  filterUpdate(event?) {
    const val = event ? event.target.value.toLowerCase() : this.filteredUserValue;
    this.filteredUserValue = val;

    // filter our data
    const temp = this.tempUserList.filter(function (d) {
      return d.branch.toLowerCase().indexOf(val) !== -1 || d.name.toLowerCase().indexOf(val) !== -1 || d.employeeId.toLowerCase().indexOf(val) !== -1 || d.contact.toLowerCase().indexOf(val) !== -1 || !val;
    });
    // update the rows
    this.kitchenSinkRows = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
  }

  toggleBottomSheet() {
    if (this.showBottomSheet) {
      this.isEditForm = false;
      this.userForm.reset();
    }

    this.showBottomSheet = !this.showBottomSheet;

    this.addButtonIcon = this.showBottomSheet ? "x-circle" : "plus";
  }

  editUserOption(row) {
    if (this.showBottomSheet) {
      this.toggleBottomSheet();
    }

    this.isEditForm = true;
    this.selectedUserData = row;

    this.userForm.patchValue({
      name: this.selectedUserData.name,
      userName: this.selectedUserData.userName,
      role: this.selectedUserData.role,
      branch: this.selectedUserData.branch,
      designation: this.selectedUserData.designation,
      officialMail: this.selectedUserData.officialMail,
      contact: this.selectedUserData.contact,
      address: this.selectedUserData.address,
      employeeId: this.selectedUserData.employeeId,
      joiningData: this.selectedUserData.joiningData,
      department: this.selectedUserData.department,
    });
    this.toggleBottomSheet();
  }

  assignFormOption(row, modRef, size) {
    this.selectedUserData = row;
    this.modalOpen(modRef, size);
  }

  userDetailOption(row, modRef, size) {
    this.selectedUserData = row;
    this.modalOpen(modRef, size);
  }

  onFormSubmit() {
    if (this.userForm.valid) {
      if (this.isEditForm) {
        this.editUser();
      } else {
        this.addUser();
      }
    } else {
      return;
    }
  }

  decideActionForm(form) {
    return this.selectedUserData.assignedForm.includes(form.formId);
  }
  decideActionSheet(sheet) {
    return this.selectedUserData.assignedSheet.includes(sheet.sheetId);
  }
}
