import { AfterViewInit, Component, ElementRef, HostListener, OnInit, Renderer2, ViewChild } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { BackendServiceService } from "app/service/backend-service.service";
import { ToastrServiceService } from "app/service/toastrservice.service";
import { SwiperConfigInterface } from "ngx-swiper-wrapper";

@Component({
  selector: "app-role",
  templateUrl: "./role.component.html",
  styleUrls: ["./role.component.scss"],
})
export class RoleComponent implements OnInit, AfterViewInit {
  public roleList: Array<any>;
  public tempRoleList: any;
  public formCategoryList: any;
  public tempFormCategoryList: any;
  public userList: any;
  public tempUserList: any;
  public addRoleForm: FormGroup;
  public customTag: any[] = [];
  public customTagselected;
  public tempFormPermission = [];
  public tempUserPermission = [];
  public tempRolePermission = [];
  showDetails: Boolean = true;
  public centeredSlide2Index: number = 2;
  @ViewChild("swiperContainer", { static: true }) swiperContainer: ElementRef;

  selectedRoleId: String;
  selectedRoleData: any;
  roleIndex: number = 0;

  isEditForm: Boolean = false;

  constructor(private backendService: BackendServiceService, private modalService: NgbModal, private fb: FormBuilder, private toster: ToastrServiceService, private renderer: Renderer2) {}

  public swiperswiperCenteredSlides: SwiperConfigInterface = {
    slidesPerView: 1,
    // centeredSlides: true,
    breakpoints: {
      1024: {
        slidesPerView: 4,
        spaceBetween: 40,
      },
      768: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
      640: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
      320: {
        slidesPerView: 1,
        spaceBetween: 10,
      },
    },
  };

  ngOnInit(): void {
    this.getRoleList();
    this.createRoleForm();
  }

  ngAfterViewInit(): void {
    // this.swiperInjection();
  }

  swiperIndexChange(index) {
    // let oldSlider = document.getElementById(this.selectedRoleId.toString());
    // oldSlider.className = oldSlider.className.replace(/(\s)\bswiper-slide-active\b/g, "");
    // this.selectedRoleId = this.tempRoleList[index].roleId;
    // let slider = document.getElementById(this.selectedRoleId.toString());
    // slider.className = slider.className + " " + "swiper-slide-active";

    this.roleIndex = index;
    this.getRoleDetail();
  }

  changeRoleId(roleId) {
    this.selectedRoleId = roleId;
    this.getRoleDetail();
  }

  createRoleForm() {
    this.addRoleForm = this.fb.group({
      roleName: ["", Validators.required],
      formPermission: FormArray,
      rolePermission: FormArray,
      userPermission: FormArray,
    });
  }

  selectAddTagMethod(name) {
    return { name: name, tag: true };
  }

  swiperInjection() {
    this.renderer.setProperty(this.swiperContainer.nativeElement, "innerHTML", "");

    // Create div element with specified classes and inner HTML
    const div = this.renderer.createElement("div");

    this.renderer.addClass(div, "swiper-slide");
    this.renderer.addClass(div, "rounded");
    this.renderer.addClass(div, "swiper-shadow");
    this.renderer.addClass(div, "py-1");
    this.renderer.addClass(div, "px-3");
    this.renderer.addClass(div, "mb-1");
    this.renderer.addClass(div, "d-flex");
    div.innerHTML = `
      <i data-feather="facebook" class="mr-50 font-medium-3"></i>
      <div class="swiper-text">Pricing & Plans</div>
    `;

    this.renderer.appendChild(this.swiperContainer.nativeElement, div);
  }

  modalOpen(modalBasic, size) {
    this.getFormCategoryList();
    this.getUserList();
    this.modalService.open(modalBasic, {
      centered: true,
      backdrop: "static",
      keyboard: true,
      size: size,
    });
  }

  setRoleForEdit() {
    this.isEditForm = true;
    this.addRoleForm.patchValue({
      roleName: this.selectedRoleData.roleName,
    });
    this.tempFormPermission = this.selectedRoleData.formPermissionDetails.map((data) => {
      return {
        permittedId: data.formCategoryId,
        name: data.formCategoryName,
        permittedAction: data.permittedAction,
      };
    });
    this.tempRolePermission = this.selectedRoleData.rolePermissionDetails.map((data) => {
      return {
        permittedId: data.roleId,
        name: data.roleName,
        permittedAction: data.permittedAction,
      };
    });
    this.tempUserPermission = this.selectedRoleData.userPermissionDetails.map((data) => {
      return {
        permittedId: data.roleId,
        name: data.roleName,
        permittedAction: data.permittedAction,
      };
    });

    // this.addRoleForm.setValue({ roleName: this.addRoleForm.value.roleName, formPermission: this.tempFormPermission || [], rolePermission: this.tempRolePermission || [], userPermission: this.tempUserPermission || [] });

    // this.roleData = row;
    // this.customerDetails = this.customerData;
    // if (loadMeasurement) {
    //   this.getCustomerMeasurement(this.customerData.customerId);
    //   return;
    // }
  }

  getRoleList() {
    this.backendService.getRoleList().subscribe((res) => {
      this.roleList = res.items;
      this.tempRoleList = this.roleList;
      if (this.tempRoleList[0]) {
        this.selectedRoleId = this.tempRoleList[0].roleId;

        this.roleIndex = 0;

        this.getRoleDetail();
      }
    });
  }

  addNewRole(bodyData) {
    this.backendService.addNewRole(bodyData).subscribe((res) => {
      this.getRoleList();
      this.modalCloseFn();
    });
  }
  updateNewRole(bodyData) {
    this.backendService.updateNewRole(bodyData, this.selectedRoleId).subscribe((res) => {
      this.getRoleList();
      this.modalCloseFn();
    });
  }

  getRoleDetail() {
    this.backendService.getRoleDetail(this.selectedRoleId).subscribe((res) => {
      this.selectedRoleData = res.items;
    });
  }

  getFormCategoryList() {
    this.backendService.getFormCategoryList().subscribe((res) => {
      this.formCategoryList = res.items;
      this.tempFormCategoryList = this.formCategoryList;
    });
  }
  getUserList() {
    this.backendService.getUserList().subscribe((res) => {
      this.userList = res.items;
      this.tempUserList = this.userList;
    });
  }

  addPermittedUser($event) {
    // this.addRoleForm.controls.formPermission;
    let tempData = this.tempUserPermission;
    this.tempUserPermission = [];
    this.tempUserPermission = $event.map((data, index) => {
      return {
        permittedId: data.roleId,
        name: data.roleName,
        permittedAction: tempData[index]?.permittedId == data.roleId && tempData[index]?.permittedAction ? tempData[index].permittedAction : ["read"],
      };
    });
  }

  addPermittedRole($event) {

    // this.addRoleForm.controls.formPermission;
    let tempData = this.tempRolePermission;

    this.tempRolePermission = [];
    this.tempRolePermission = $event.map((data,index) => {

      return {
        permittedId: data.roleId || data.permittedId,
        name: data.roleName || data.name,
        permittedAction:tempData[index]?.permittedId == data.roleId && tempData[index]?.permittedAction ? tempData[index].permittedAction : ["read"],
      };
    });
  }

  addPermittedForm($event) {
    // this.addRoleForm.controls.formPermission;
    let tempData = this.tempFormPermission;

    this.tempFormPermission = [];
    this.tempFormPermission = $event.map((data,index) => {
      return {
        permittedId: data.formCategoryId,
        name: data.formCategoryName,
        permittedAction: tempData[index]?.permittedId == data.formCategoryId && tempData[index]?.permittedAction ? tempData[index].permittedAction : ["read"],
      };
    });
  }

  changePermittedAction(permissionType, permittedId, action) {
    switch (permissionType) {
      case "form":
        this.addRemovePermittedAction(permittedId, this.tempFormPermission, action);
        break;
      case "role":
        this.addRemovePermittedAction(permittedId, this.tempRolePermission, action);
        break;
      case "user":
        this.addRemovePermittedAction(permittedId, this.tempUserPermission, action);
        break;
    }
  }

  addRemovePermittedAction(permittedId, dataList, action) {
    dataList.forEach((data) => {
      if (data.permittedId == permittedId) {
        if (data.permittedAction.includes(action)) {
          data.permittedAction = data.permittedAction.filter((data) => data != action);
        } else {
          data.permittedAction.push(action);
        }
      }
    });
  }

  submitForm() {
    this.addRoleForm.setValue({ roleName: this.addRoleForm.value.roleName, formPermission: this.tempFormPermission, rolePermission: this.tempRolePermission, userPermission: this.tempUserPermission });

    if (this.isEditForm) {
      this.updateNewRole(this.addRoleForm.value);
    } else {
      this.addNewRole(this.addRoleForm.value);
    }
  }

  modalCloseFn() {
    if (this.isEditForm) {
      this.isEditForm = false;
    }
    this.addRoleForm.reset();
    this.addRoleForm.value.roleName = [];
    this.tempFormPermission = [];
    this.tempRolePermission = [];
    this.tempUserPermission = [];
    this.modalService.dismissAll();
  }

  testChange($event) {}
}
