<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
  
       <!-- account setting page -->
       <section id="page-account-settings">
        <div class="row nav-vertical d-flex">
          <div class="col-md-3 mb-2 mb-md-0">
            <div class="card">
              <div class="card-header">
                <h4 class="card-title">Role List</h4>
                <div class="mb-50 d-flex justify-content-end">
                  <button class="btn btn-primary ml-1" (click)="modalOpen(addRoleModel, 'lg')" rippleEffect><i data-feather="plus" class="ml-0 mr-sm-1"></i><span class="d-none d-sm-inline-block">Add Role</span></button>
                </div>
              </div>
              <div class="card-body">
                <section id="nav-filled-justified">
                  <div class="row match-height">
                    <div class="col-xl-12 col-lg-12">
                        <div class="card-body">
                          <ul ngbNav #navVertical="ngbNav" [activeId]="selectedRoleId"  class="nav nav-pills flex-column nav-left">
                            <li class="nav-item" [ngbNavItem]="role.roleId" *ngFor="let role of tempRoleList">
                              
                              <a
                                ngbNavLink
                                class="nav-link d-flex py-75"
                                id="account-pill-general"
                                data-toggle="pill"
                                href="#account-vertical-general"
                                aria-expanded="true"
                                (click)="changeRoleId(role.roleId)"
                              >
                                <i data-feather="user" class="font-medium-3 mr-1"></i>
                                <span class="font-weight-bold">{{role.roleName}}</span>
                              </a>
                              <ng-template ngbNavContent>
                                <div class="card">
                                  <div class="card-body">
                                    <div class="media-body mt-75 ml-1 d-flex justify-content-between">
                                      <h1>{{ role.roleName }}</h1>
                                      <button class="btn btn-sm btn-outline-secondary mb-75" (click)="setRoleForEdit();modalOpen(addRoleModel, 'lg')" rippleEffect>Edit</button>
                                    </div>
                                  </div>
                                </div>
              
                                <div class="row match-height" *ngIf="showDetails && selectedRoleId && selectedRoleData">
                                  <!-- Employee Task Card -->
                                  <div class="col-12 ml-1">
                                    <h3>Permissions</h3>
                                  </div>
                            
                                  <div class="col-lg-4 col-md-6 col-12" *ngIf="showDetails && selectedRoleId && selectedRoleData.formPermissionDetails && selectedRoleData?.formPermissionDetails[0]">
                                    <div class="card card-employee-task">
                                      <div class="card-header">
                                        <h4 class="card-title">Form Permission</h4>
                                      </div>
                                      <div class="card-body">
                                        <div class="employee-task" *ngFor="let form of selectedRoleData.formPermissionDetails">
                                          <div class="row">
                                            <div class="col-md-12 col-sm-12">
                                              <core-card [actions]="['collapse']">
                                                <h4 class="card-title">{{ form.formCategoryName }}</h4>
                                                <div class="card-body d-flex align-items-center justify-content-right">
                                                  <p>
                                                    {{ form.permittedAction }}
                                                  </p>
                                                </div>
                                              </core-card>
                                            </div>
                                            <!-- Collapsible and Refresh Action Ends -->
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-lg-4 col-md-6 col-12" *ngIf="showDetails && selectedRoleId && selectedRoleData.userPermissionDetails && selectedRoleData?.userPermissionDetails[0]">
                                    <div class="card card-employee-task">
                                      <div class="card-header">
                                        <h4 class="card-title">User Permission</h4>
                                      </div>
                                      <div class="card-body">
                                        <div class="employee-task" *ngFor="let user of selectedRoleData.userPermissionDetails">
                                          <div class="row">
                                            <div class="col-md-12 col-sm-12">
                                              <core-card [actions]="['collapse']">
                                                <h4 class="card-title">{{ user.roleName }}</h4>
                            
                                                <div class="card-body d-flex align-items-center justify-content-right">
                                                  <p>
                                                    {{ user.permittedAction }}
                                                  </p>
                                                </div>
                                              </core-card>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-lg-4 col-md-6 col-12" *ngIf="showDetails && selectedRoleId && selectedRoleData.rolePermissionDetails && selectedRoleData?.rolePermissionDetails[0]">
                                    <div class="card card-employee-task">
                                      <div class="card-header">
                                        <h4 class="card-title">Role Permission</h4>
                                      </div>
                                      <div class="card-body">
                                        <div class="employee-task" *ngFor="let role of selectedRoleData.rolePermissionDetails">
                                          <div class="row">
                                            <div class="col-md-12 col-sm-12">
                                              <core-card [actions]="['collapse']">
                                                <h4 class="card-title">{{ role.roleName }}</h4>
                            
                                                <div class="card-body d-flex align-items-center justify-content-right">
                                                  <p>
                                                    {{ role.permittedAction }}
                                                  </p>
                                                </div>
                                              </core-card>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <!--/ Employee Task Card -->
                                </div>
                              </ng-template>
                            </li>
                          </ul>
                        </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
          <div [ngbNavOutlet]="navVertical" class="col-12 col-md-9"></div>
        </div>
      </section>
      <!--/ account setting page -->
  </div>
</div>

<!-- add Modal -->
<ng-template #addRoleModel let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="myModalLabel1">{{isEditForm?'Edit Role':'Add new Role'}}</h4>
    <button type="button" class="close" (click)="modalCloseFn()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" tabindex="0" ngbAutofocus>
    <div class="content-wrapper container-xxl p-0">
      <div class="content-body">
        <form [formGroup]="addRoleForm" class="form form-horizontal">
          <div class="row">
            <div class="col-12">
              <div class="form-group row">
                <div class="col-sm-3 col-form-label">
                  <label>Role Name</label>
                </div>
                <div class="col-sm-9">
                  <div class="input-group input-group-merge">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><span [data-feather]="'user'"></span></span>
                    </div>
                    <input type="text" id="fname-icon" formControlName="roleName" class="form-control" name="fname-icon" placeholder="Role Name" />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="form-group row">
                <div class="col-sm-3 col-form-label">
                  <label>Permitted Form</label>
                </div>
                <div class="col-sm-9">
                  <ng-select [items]="tempFormCategoryList" [addTag]="selectAddTagMethod" [hideSelected]="true" multiple="true" bindLabel="formCategoryName"  (change)="addPermittedForm($event)">
                    <ng-template ng-label-tmp let-item="item" let-clear="clear">
                      <span class="ng-value-label"> {{ item.formCategoryName }}</span>
                      <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                    </ng-template>
                  </ng-select>
                </div>
              </div>
            </div>
            <!-- permitted action for form -->
            <div class="col-12" *ngIf="tempFormPermission[0]">
              <div class="form-group row" *ngFor="let item of tempFormPermission,index as i">
                <div class="col-sm-3 col-form-label">
                  <label>{{ item.name }}</label>
                </div>
                <div class="col-sm-9">
                  <div class="custom-control custom-switch custom-control-inline">
                    <input type="checkbox" class="custom-control-input" [checked]="tempFormPermission[i].permittedAction.includes('edit')" (change)="changePermittedAction('form', item.permittedId, 'edit')" [id]="item.permittedId + 1" />
                    <label class="custom-control-label" [for]="item.permittedId + 1">Edit</label>
                  </div>
                  <div class="custom-control custom-switch custom-control-inline">
                    <input type="checkbox" class="custom-control-input" [checked]="tempFormPermission[i].permittedAction.includes('add')" (change)="changePermittedAction('form', item.permittedId, 'add')" [id]="item.permittedId + 2" />
                    <label class="custom-control-label" [for]="item.permittedId + 2">Add</label>
                  </div>
                  <div class="custom-control custom-switch custom-control-inline">
                    <input type="checkbox" class="custom-control-input" [checked]="tempFormPermission[i].permittedAction.includes('delete')" (change)="changePermittedAction('form', item.permittedId, 'delete')" [id]="item.permittedId + 3" />
                    <label class="custom-control-label" [for]="item.permittedId + 3">Delete</label>
                  </div>
                </div>
              </div>
            </div>
            <!-- permitted action for form -->

            <!-- <div class="permitted-action-section" *ngIf="tempFormPermission[0]">
            </div> -->
            <div class="col-12">
              <div class="form-group row">
                <div class="col-sm-3 col-form-label">
                  <label>Permitted Roles</label>
                </div>
                <div class="col-sm-9">
                  <ng-select [items]="tempRoleList" [addTag]="selectAddTagMethod" [hideSelected]="true" multiple="true" bindLabel="roleName"   (change)="addPermittedRole($event)">
                    <ng-template ng-label-tmp let-item="item" let-clear="clear">
                      <span class="ng-value-label"> {{ item.roleName }}</span>
                      <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                    </ng-template>
                  </ng-select>
                </div>
              </div>
            </div>
            <!-- permitted action for role -->
            <div class="col-12" *ngIf="tempRolePermission[0]">
              <div class="form-group row" *ngFor="let item of tempRolePermission, index as i">
                <div class="col-sm-3 col-form-label">
                  <label>{{ item.name }}</label>
                </div>
                <div class="col-sm-9">
                  <div class="custom-control custom-switch custom-control-inline">
                    <input type="checkbox" class="custom-control-input" [active]="true"  [checked]="tempRolePermission[i].permittedAction.includes('edit')" (change)="changePermittedAction('role', item.permittedId, 'edit')" [id]="item.permittedId + 1" />
                    <label class="custom-control-label" [for]="item.permittedId + 1">Edit</label>
                  </div>
                  <div class="custom-control custom-switch custom-control-inline">
                    <input type="checkbox" class="custom-control-input"  [checked]="tempRolePermission[i].permittedAction.includes('add')" (change)="changePermittedAction('role', item.permittedId, 'add')" [id]="item.permittedId + 2" />
                    <label class="custom-control-label" [for]="item.permittedId + 2">Add</label>
                  </div>
                  <div class="custom-control custom-switch custom-control-inline">
                    <input type="checkbox" class="custom-control-input"  [checked]="tempRolePermission[i].permittedAction.includes('delete')" (change)="changePermittedAction('role', item.permittedId, 'delete')" [id]="item.permittedId + 3" />
                    <label class="custom-control-label" [for]="item.permittedId + 3">Delete</label>
                  </div>
                </div>
              </div>
            </div>
            <!-- permitted action for form -->
            <div class="col-12">
              <div class="form-group row">
                <div class="col-sm-3 col-form-label">
                  <label>Permitted Users</label>
                </div>
                <div class="col-sm-9">
                  <ng-select [items]="tempRoleList" [hideSelected]="true" multiple="true" bindLabel="roleName" (change)="addPermittedUser($event)">
                    <ng-template ng-label-tmp let-item="item" let-clear="clear">
                      <span class="ng-value-label"> {{ item.roleName }}</span>
                      <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                    </ng-template>
                  </ng-select>
                  <!-- <button (click)="testButton()">test</button> -->
                </div>
              </div>
            </div>
            <!-- permitted action for user -->
            <div class="col-12" *ngIf="tempUserPermission[0]">
              <div class="form-group row" *ngFor="let item of tempUserPermission, index as i">
                <div class="col-sm-3 col-form-label">
                  <label>{{ item.name }}</label>
                </div>
                <div class="col-sm-9">
                  <div class="custom-control custom-switch custom-control-inline">
                    <input type="checkbox" class="custom-control-input"  [checked]="tempUserPermission[i].permittedAction.includes('edit')" (change)="changePermittedAction('user', item.permittedId, 'edit')" [id]="item.permittedId + 4" />
                    <label class="custom-control-label" [for]="item.permittedId + 4">Edit</label>
                  </div>
                  <div class="custom-control custom-switch custom-control-inline">
                    <input type="checkbox" class="custom-control-input" [checked]="tempUserPermission[i].permittedAction.includes('add')"  (change)="changePermittedAction('user', item.permittedId, 'add')" [id]="item.permittedId + 5" />
                    <label class="custom-control-label" [for]="item.permittedId + 5">Add</label>
                  </div>
                  <div class="custom-control custom-switch custom-control-inline">
                    <input type="checkbox" class="custom-control-input" [checked]="tempUserPermission[i].permittedAction.includes('delete')"  (change)="changePermittedAction('user', item.permittedId, 'delete')" [id]="item.permittedId + 6" />
                    <label class="custom-control-label" [for]="item.permittedId + 6">Delete</label>
                  </div>
                </div>
              </div>
            </div>
            <!-- permitted action for form -->
            <div class="col-sm-9 offset-sm-3">
              <button type="submit" (click)="submitForm()" rippleEffect class="btn btn-primary mr-1">Submit</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-template>
<!-- / Modal -->

<!-- add Modal -->
<ng-template #roleDetail let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="myModalLabel1">Add new Role</h4>
    <button type="button" class="close" (click)="modalCloseFn()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" tabindex="0" ngbAutofocus>
    <div class="content-wrapper container-xxl p-0">
      <div class="content-body">
        <form [formGroup]="addRoleForm" class="form form-horizontal">
          <div class="row">
            <div class="col-12">
              <div class="form-group row">
                <div class="col-sm-3 col-form-label">
                  <label>Role Name</label>
                </div>
                <div class="col-sm-9">
                  <div class="input-group input-group-merge">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><span [data-feather]="'user'"></span></span>
                    </div>
                    <input type="text" id="fname-icon" formControlName="roleName" class="form-control" name="fname-icon" placeholder="Role Name" />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="form-group row">
                <div class="col-sm-3 col-form-label">
                  <label>Permitted Form</label>
                </div>
                <div class="col-sm-9">
                  <ng-select [items]="tempFormCategoryList" [addTag]="selectAddTagMethod" [hideSelected]="true" multiple="true" bindLabel="formCategoryName" (change)="addPermittedForm($event)">
                    <ng-template ng-label-tmp let-item="item" let-clear="clear">
                      <span class="ng-value-label"> {{ item.formCategoryName }}</span>
                      <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                    </ng-template>
                  </ng-select>
                </div>
              </div>
            </div>
            <!-- permitted action for form -->
            <div class="col-12" *ngIf="tempFormPermission[0]">
              <div class="form-group row" *ngFor="let item of tempFormPermission">
                <div class="col-sm-3 col-form-label">
                  <label>{{ item.name }}</label>
                </div>
                <div class="col-sm-9">
                  <div class="custom-control custom-switch custom-control-inline">
                    <input type="checkbox" class="custom-control-input" (change)="changePermittedAction('form', item.permittedId, 'edit')" [id]="item.permittedId + 1" />
                    <label class="custom-control-label" [for]="item.permittedId + 1">Edit</label>
                  </div>
                  <div class="custom-control custom-switch custom-control-inline">
                    <input type="checkbox" class="custom-control-input" (change)="changePermittedAction('form', item.permittedId, 'add')" [id]="item.permittedId + 2" />
                    <label class="custom-control-label" [for]="item.permittedId + 2">Add</label>
                  </div>
                  <div class="custom-control custom-switch custom-control-inline">
                    <input type="checkbox" class="custom-control-input" (change)="changePermittedAction('form', item.permittedId, 'delete')" [id]="item.permittedId + 3" />
                    <label class="custom-control-label" [for]="item.permittedId + 3">Delete</label>
                  </div>
                </div>
              </div>
            </div>
            <!-- permitted action for form -->

            <!-- <div class="permitted-action-section" *ngIf="tempFormPermission[0]">
            </div> -->
            <div class="col-12">
              <div class="form-group row">
                <div class="col-sm-3 col-form-label">
                  <label>Permitted Roles</label>
                </div>
                <div class="col-sm-9">
                  <ng-select [items]="tempRoleList" [addTag]="selectAddTagMethod" [hideSelected]="true" multiple="true" bindLabel="roleName" (change)="addPermittedRole($event)">
                    <ng-template ng-label-tmp let-item="item" let-clear="clear">
                      <span class="ng-value-label"> {{ item.roleName }}</span>
                      <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                    </ng-template>
                  </ng-select>
                </div>
              </div>
            </div>
            <!-- permitted action for role -->
            <div class="col-12" *ngIf="tempRolePermission[0]">
              <div class="form-group row" *ngFor="let item of tempRolePermission">
                <div class="col-sm-3 col-form-label">
                  <label>{{ item.name }}</label>
                </div>
                <div class="col-sm-9">
                  <div class="custom-control custom-switch custom-control-inline">
                    <input type="checkbox" class="custom-control-input" (change)="changePermittedAction('role', item.permittedId, 'edit')" [id]="item.permittedId + 1" />
                    <label class="custom-control-label" [for]="item.permittedId + 1">Edit</label>
                  </div>
                  <div class="custom-control custom-switch custom-control-inline">
                    <input type="checkbox" class="custom-control-input" (change)="changePermittedAction('role', item.permittedId, 'add')" [id]="item.permittedId + 2" />
                    <label class="custom-control-label" [for]="item.permittedId + 2">Add</label>
                  </div>
                  <div class="custom-control custom-switch custom-control-inline">
                    <input type="checkbox" class="custom-control-input" (change)="changePermittedAction('role', item.permittedId, 'delete')" [id]="item.permittedId + 3" />
                    <label class="custom-control-label" [for]="item.permittedId + 3">Delete</label>
                  </div>
                </div>
              </div>
            </div>
            <!-- permitted action for form -->
            <div class="col-12">
              <div class="form-group row">
                <div class="col-sm-3 col-form-label">
                  <label>Permitted Users</label>
                </div>
                <div class="col-sm-9">
                  <ng-select [items]="tempRoleList" [hideSelected]="true" multiple="true" bindLabel="roleName" (change)="addPermittedUser($event)">
                    <ng-template ng-label-tmp let-item="item" let-clear="clear">
                      <span class="ng-value-label"> {{ item.roleName }}</span>
                      <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                    </ng-template>
                  </ng-select>
                  <!-- <button (click)="testButton()">test</button> -->
                </div>
              </div>
            </div>
            <!-- permitted action for user -->
            <div class="col-12" *ngIf="tempUserPermission[0]">
              <div class="form-group row" *ngFor="let item of tempUserPermission">
                <div class="col-sm-3 col-form-label">
                  <label>{{ item.name }}</label>
                </div>
                <div class="col-sm-9">
                  <div class="custom-control custom-switch custom-control-inline">
                    <input type="checkbox" class="custom-control-input" (change)="changePermittedAction('user', item.permittedId, 'edit')" [id]="item.permittedId + 4" />
                    <label class="custom-control-label" [for]="item.permittedId + 4">Edit</label>
                  </div>
                  <div class="custom-control custom-switch custom-control-inline">
                    <input type="checkbox" class="custom-control-input" (change)="changePermittedAction('user', item.permittedId, 'add')" [id]="item.permittedId + 5" />
                    <label class="custom-control-label" [for]="item.permittedId + 5">Add</label>
                  </div>
                  <div class="custom-control custom-switch custom-control-inline">
                    <input type="checkbox" class="custom-control-input" (change)="changePermittedAction('user', item.permittedId, 'delete')" [id]="item.permittedId + 6" />
                    <label class="custom-control-label" [for]="item.permittedId + 6">Delete</label>
                  </div>
                </div>
              </div>
            </div>
            <!-- permitted action for form -->
            <div class="col-sm-9 offset-sm-3">
              <button type="submit" (click)="submitForm()" rippleEffect class="btn btn-primary mr-1">Submit</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-template>
<!-- / Modal -->
