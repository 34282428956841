import { Component, HostListener, OnInit, QueryList, ViewChild, ViewChildren } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbModal, NgbPopover } from "@ng-bootstrap/ng-bootstrap";
import { BackendServiceService } from "app/service/backend-service.service";
import { ToastrServiceService } from "app/service/toastrservice.service";

@Component({
  selector: 'app-sheet',
  templateUrl: './sheet.component.html',
  styleUrls: ['./sheet.component.scss']
})
export class SheetComponent implements OnInit {
  public sheetList: Array<any>;
  public sheetCategoryList: Array<any>;
  public tempSheetList: any;
  public tempSheetCategoryList: any;
  sheetCategoryForm: FormGroup;
  selectedSheetCategoryId;
  leadSheet: FormGroup;
  tempRoleList: any;
  roleList: any;
  isMobile = window.innerWidth < 576;
  selectSheetCategoryData;
  selectSheetData;

  @ViewChildren("formPopover") formPopovers: QueryList<NgbPopover>;
  formPopOverIndex: any;
  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.isMobile = window.innerWidth < 576; // Adjust the threshold as per your requirement
  }

  constructor(private backendService: BackendServiceService, private modalService: NgbModal, private fb: FormBuilder, private toster: ToastrServiceService) {
    this.sheetCategoryForm = this.fb.group({
      sheetCategoryName: ["", Validators.required],
      defaultRole: FormArray,
    });
    this.leadSheet = this.fb.group({
      sheetCategoryId: ["", Validators.required],
      sheetName: ["", Validators.required],
      sheetDescription: ["", Validators.required],
      sheetLink: ["", Validators.required],
    });
  }

  modalOpen(modalBasic, size) {
    
    this.modalService.open(modalBasic, {
      centered: true,
      backdrop: "static",
      keyboard: true,
      size: size,
    });
  }

  ngOnInit(): void {
    this.getSheetCategoryList();
    this.getRoleList();
  }

  addNewSheetCategory() {
    const bodyData = this.sheetCategoryForm.value;
    this.backendService.addSheetCategory(bodyData).subscribe((res) => {
      this.getSheetCategoryList();
    });
  }
  updateSheetCategory() {
    const bodyData = this.sheetCategoryForm.value;
    this.backendService.updateSheetCategory(bodyData, this.selectSheetCategoryData.sheetCategoryId).subscribe((res) => {
      if (res.status) {
        this.getSheetCategoryList();
        this.toster.showSuccess(res.message, "Success");
      } else {
        this.toster.showError(res.message, "Error");
      }
      this.selectSheetCategoryData = null;
      this.sheetCategoryForm.reset();
      this.modalService.dismissAll();
    });
  }
  changefn(event){
    
  }
  addNewSheet() {
    const bodyData = this.leadSheet.value;
    this.backendService.addSheet(bodyData).subscribe((res) => {
      this.getSheetCategoryList();
    });
  }

  updateSheet() {
    const bodyData = this.leadSheet.value;
    
    this.backendService.updateSheet(bodyData,  this.selectSheetData.sheetId).subscribe((res) => {
      if (res.status) {
        this.getSheetCategoryList();
        this.toster.showSuccess(res.message, "Success");
      } else {
        this.toster.showError(res.message, "Error");
      }
      this.selectSheetData = null;
      this.leadSheet.reset();
      this.modalService.dismissAll();
    });
  }

  getSheetCategoryList() {
    this.backendService.getSheetCategoryList().subscribe((res) => {
      this.sheetCategoryList = res.items;
      this.tempSheetCategoryList = this.sheetCategoryList;
    });
  }

  submitCategorySheet(popover) {
    if (this.sheetCategoryForm.valid) {
      this.addNewSheetCategory();
      popover.close();
      this.sheetCategoryForm.reset();
    } else {
      return;
    }
  }

  submitUpdateCategorySheet() {
    if (this.sheetCategoryForm.valid) {
      this.updateSheetCategory();
    } else {
      return;
    }
  }

  submitSheet(index) {
    this.leadSheet.controls.sheetCategoryId.setValue(this.selectedSheetCategoryId);

    if (this.leadSheet.valid) {
      this.addNewSheet();
      this.formPopovers.toArray()[this.formPopOverIndex].close();
      this.leadSheet.reset();
    } else {
      return;
    }
  }

  submitUpdateSheet() {
    if (!this.leadSheet.invalid) {
      this.updateSheet();
    } else {
      this.toster.showError("Please provide proper value","error")
      return;
    }
  }

  editSheetCategoryOption(mRef, sheetCategoryData) {
    this.selectSheetCategoryData = sheetCategoryData;
    
    this.sheetCategoryForm.patchValue({
      sheetCategoryName: this.selectSheetCategoryData.sheetCategoryName,
      defaultRole: this.selectSheetCategoryData.defaultRole,
    });
    this.modalOpen(mRef, "lg");
  }

  editSheetOption(mRef, sheetData) {
    this.selectSheetData = sheetData;

    this.leadSheet.patchValue({
      sheetCategoryId: this.selectSheetData.sheetCategoryId,
      sheetName: this.selectSheetData.sheetName,
      sheetDescription: this.selectSheetData.sheetDescription,
      sheetLink: this.selectSheetData.sheetLink,
    });
    this.modalOpen(mRef, "sm");
  }

  getRoleList() {
    this.backendService.getRoleList().subscribe((res) => {
      this.roleList = res.items;
      this.tempRoleList = this.roleList;
    });
  }

  recordShown(sheetCategoryId, index) {
    this.formPopOverIndex = index;

    this.selectedSheetCategoryId = sheetCategoryId;
  }
  recordHidden() {
    this.selectedSheetCategoryId = null;
  }

  closePopOver() {
    this.formPopovers.toArray()[this.formPopOverIndex].close();
  }

  modalCloseFn() {
    this.sheetCategoryForm.reset();
    this.leadSheet.reset();
    this.modalService.dismissAll();
    this.selectSheetCategoryData = null;
    this.selectSheetData = null;
  }
}
