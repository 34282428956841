export const locale = {
  lang: 'en',
  data: {
    MENU: {
      HOME: 'Home',
      ROLE:'Role',
      FORM:'Form',
      USER:'User',
      SHEET:'Sheet'
    }
  }
}
