<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <div class="card">
      <div class="card-header">
        <a csvLink [data]="exportCSVData" class="btn btn-outline-secondary float-right" rippleEffect>Export CSV</a>

        <h4 class="card-title">User List</h4>

        <button class="btn btn-primary ml-1" (click)="toggleBottomSheet()" rippleEffect>
          <i class="feather mr-0 mr-sm-1" [ngClass]="showBottomSheet ? 'icon-x' : 'icon-plus'"></i><span class="d-none d-sm-inline-block">{{ showBottomSheet ? "Close Form" : "Add New User" }}</span>
        </button>
      </div>

      <!--? content wrapped with .card-body -->
      <div class="card-body">
        <section *ngIf="showBottomSheet">
          <div class="row match-height card">
            <div class="col-12">
              <div class="d-flex align-items-center justify-content-center">
                <h4 class="card-title">{{ isEditForm ? "Edit User" : "Add New User" }}</h4>
              </div>
              <div class="card-body">
                <form class="form" [formGroup]="userForm">
                  <div class="row">
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <label for="first-name-column">Name</label>
                        <input type="text" id="first-name-column" formControlName="name" class="form-control" placeholder="Name of User" name="fname-column" />
                      </div>
                    </div>
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <label for="user-username">Username</label>
                        <input type="text" id="user-username" formControlName="userName" class="form-control" placeholder="Username" name="user-username" />
                      </div>
                    </div>
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <label for="user-password">Password</label>
                        <div class="input-group form-password-toggle mb-2">
                          <input [type]="showPassword ? 'text' : 'password'" class="form-control" id="user-password" formControlName="password" placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;" aria-describedby="basic-default-password" />
                          <div class="input-group-append" (click)="showPassword = !showPassword">
                            <span class="input-group-text cursor-pointer"
                              ><i
                                class="feather"
                                [ngClass]="{
                                  'icon-eye-off': showPassword,
                                  'icon-eye': !showPassword
                                }"
                              ></i
                            ></span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <label for="user-role">Role</label>
                        <ng-select id="user-role" [items]="tempRoleList" formControlName="role" [addTag]="selectAddTagMethod" [hideSelected]="true" bindLabel="roleName" bindValue="roleId">
                          <ng-template ng-label-tmp let-item="item" let-clear="clear">
                            <span class="ng-value-label"> {{ item.roleName }}</span>
                            <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                          </ng-template>
                        </ng-select>
                      </div>
                    </div>

                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <label for="user-Branch">Branch</label>
                        <input type="text" id="user-Branch" formControlName="branch" class="form-control" placeholder="Branch" name="user-Branch" />
                      </div>
                    </div>
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <label for="user-designation">Designation</label>
                        <input type="text" id="user-designation" formControlName="designation" class="form-control" placeholder="designation" name="user-designation" />
                      </div>
                    </div>
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <label for="user-department">Department</label>
                        <input type="text" id="user-department" formControlName="department" class="form-control" placeholder="department" name="user-department" />
                      </div>
                    </div>
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <label for="user-officialMail">Official E-Mail</label>
                        <input type="text" id="user-officialMail" formControlName="officialMail" class="form-control" placeholder="officialMail" name="user-officialMail" />
                      </div>
                    </div>
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <label for="user-contact">Contact</label>
                        <input type="text" id="user-contact" formControlName="contact" class="form-control" placeholder="contact" name="user-contact" />
                      </div>
                    </div>
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <label for="user-address">Address</label>
                        <input type="text" id="user-address" formControlName="address" class="form-control" placeholder="address" name="user-address" />
                      </div>
                    </div>
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <label for="user-employeeId">Employee Id</label>
                        <input type="text" id="user-employeeId" formControlName="employeeId" class="form-control" placeholder="employeeId" name="user-employeeId" />
                      </div>
                    </div>
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <label for="user-joiningData">Joining Date</label>
                        <input type="text" id="user-joiningData" formControlName="joiningData" class="form-control" placeholder="joiningData" name="user-joiningData" />
                      </div>
                    </div>
                    <div class="col-12 d-flex flex-column">
                      <button type="submit" (click)="onFormSubmit()" rippleEffect class="btn btn-primary mx-2 my-2">Submit</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
        <section id="ngx-datatable-kitchen-sink">
          <div class="row">
            <div class="col-md-6 col-12">
              <div class="d-flex justify-content-between align-items-center m-1">
                <label class="d-flex align-items-center"
                  >Show
                  <select class="form-control mx-25" [(ngModel)]="basicSelectedOption">
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                  entries</label
                >
              </div>
            </div>
            <div class="col-md-6 col-12 d-flex justify-content-md-end">
              <div class="d-flex align-items-center pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
                <label class="d-flex align-items-center">Search<input type="search" placeholder="Search" class="form-control ml-25" (keyup)="filterUpdate($event)" (search)="filterUpdate($event)" /></label>
              </div>
            </div>
          </div>
          <ngx-datatable [rows]="kitchenSinkRows" [rowHeight]="58" class="bootstrap core-bootstrap" [limit]="10" [columnMode]="ColumnMode.force" [headerHeight]="40" [footerHeight]="50" [scrollbarH]="true" [limit]="basicSelectedOption">
            <ngx-datatable-column name="Name" prop="name" [width]="280">
              <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                <div class="d-flex align-items-center">
                  <div
                    class="avatar mr-1 ml-0"
                    [ngClass]="{
                      'bg-light-primary': row.status == '1',
                      'bg-light-success': row.status == '2',
                      'bg-light-danger': row.status == '3',
                      'bg-light-warning': row.status == '4',
                      'bg-light-info': row.status == '5'
                    }"
                  >
                    <div class="avatar-content">{{ name | initials }}</div>
                  </div>
                  <div class="cell-line-height">
                    <p class="font-medium-1 font-weight-bold line-height-1 mb-25">
                      {{ name }}
                    </p>
                  </div>
                </div>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Department" prop="department" [width]="250"></ngx-datatable-column>
            <ngx-datatable-column name="Role" prop="roleData.roleName" [width]="250"></ngx-datatable-column>
            <ngx-datatable-column name="Branch" prop="branch" [width]="250"></ngx-datatable-column>
            <ngx-datatable-column name="Actions" [width]="120" [sortable]="false">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <div class="d-flex align-items-center">
                  <div ngbDropdown container="body">
                    <a ngbDropdownToggle href="javascript:void(0);" class="hide-arrow" id="dropdownBrowserState" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <i data-feather="more-vertical" class="text-primary cursor-pointer mr-50"></i>
                    </a>
                    <div ngbDropdownMenu class="dropdown-menu-right" aria-labelledby="dropdownBrowserState">
                      <a href="javascript:void(0)" ngbDropdownItem class="d-flex align-items-center" (click)="editUserOption(row)"><i data-feather="edit" class="mr-50"></i> Edit</a>
                      <a href="javascript:void(0)" ngbDropdownItem class="d-flex align-items-center" (click)="assignFormOption(row, assignForm, 'lg')"><i data-feather="archive" class="mr-50"></i> Assign Form</a>
                      <a href="javascript:void(0)" ngbDropdownItem class="d-flex align-items-center" (click)="assignFormOption(row, assignSheet, 'lg')"><i data-feather="archive" class="mr-50"></i> Assign Sheet</a>
                      <!-- <a href="javascript:void(0)" ngbDropdownItem class="d-flex align-items-center"><i data-feather="trash-2" class="mr-50"></i> Delete</a> -->
                    </div>
                  </div>
                  <a href="javascript:void(0)" class="d-flex align-items-center" (click)="userDetailOption(row, userDetail, 'xl')">
                    <i data-feather="eye" class="text-primary cursor-pointer"></i>
                  </a>
                </div>
              </ng-template>
            </ngx-datatable-column>
          </ngx-datatable>
        </section>
      </div>
    </div>
  </div>
</div>

<!-- Basic Horizontal form layout section start -->
<!-- / Basic Horizontal form layout section end -->
<!-- add form category Modal -->
<ng-template #assignForm let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="myModalLabel1">Assign Form</h4>
    <button type="button" class="close" (click)="modalCloseFn()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" tabindex="0" ngbAutofocus>
    <div class="content-wrapper container-xxl p-0">
      <div class="content-body">
        <!-- Sortable lists section start -->
        <section id="sortable-lists">
          <div class="row">
            <!-- Multiple Lists -->
            <div class="col-sm-12">
              <div class="card-body">
                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <label for="user-role">Form Category</label>
                      <ng-select id="user-role" [items]="tempFormCategoryList" [(ngModel)]="selectedFormData" [hideSelected]="true" bindLabel="formCategoryName">
                        <ng-template ng-label-tmp let-item="item" let-clear="clear">
                          <span class="ng-value-label"> {{ item.formCategoryName }}</span>
                          <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                        </ng-template>
                      </ng-select>
                    </div>
                  </div>
                  <div class="col-6 d-flex justify-content-end align-items-center" *ngIf="selectedFormData && selectedFormData.formList[0]">
                    <button class="btn avatar btn-light-warn rounded" (click)="assignAllButtonClickForm()"><i data-feather="check" class="avatar-icon font-medium-3"></i>{{ assignAllButtonTextForm() }}</button>
                  </div>
                  <div class="col-12" *ngIf="selectedFormData && selectedFormData.formList[0]">
                    <div class="card card-transaction">
                      <div class="card-body">
                        <div class="transaction-item" *ngFor="let form of selectedFormData.formList">
                          <div class="media">
                            <div class="avatar bg-light-primary rounded">
                              <div class="avatar-content">
                                <i data-feather="paperclip" class="avatar-icon font-medium-3"></i>
                              </div>
                            </div>
                            <div class="media-body">
                              <h6 class="transaction-title">{{ form.formName }}</h6>
                              <small>{{ form.formDescription }}</small>
                            </div>
                          </div>
                          <button class="btn font-weight-bolder btn-success" *ngIf="!decideActionForm(form)" (click)="assignButtonClickForm(true, form)">Assign</button>
                          <button class="btn font-weight-bolder btn-danger" *ngIf="decideActionForm(form)" (click)="assignButtonClickForm(false, form)">Unassign</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <!--  Sortable lists section end -->
      </div>
    </div>
  </div>
</ng-template>
<!-- / Modal -->

<!-- add form category Modal -->
<ng-template #assignSheet let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="myModalLabel1">Assign Sheet</h4>
    <button type="button" class="close" (click)="modalCloseFn()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" tabindex="0" ngbAutofocus>
    <div class="content-wrapper container-xxl p-0">
      <div class="content-body">
        <!-- Sortable lists section start -->
        <section id="sortable-lists">
          <div class="row">
            <!-- Multiple Lists -->
            <div class="col-sm-12">
              <div class="card-body">
                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <label for="user-role">Sheet Category</label>
                      <ng-select id="user-sheet" [items]="tempSheetCategoryList" [(ngModel)]="selectedSheetData" [hideSelected]="true" bindLabel="sheetCategoryName">
                        <ng-template ng-label-tmp let-item="item" let-clear="clear">
                          <span class="ng-value-label"> {{ item.sheetCategoryName }}</span>
                          <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                        </ng-template>
                      </ng-select>
                    </div>
                  </div>
                  <div class="col-6 d-flex justify-content-end align-items-center" *ngIf="selectedSheetData && selectedSheetData.sheetList[0]">
                    <button class="btn avatar btn-light-warn rounded" (click)="assignAllButtonClickSheet()"><i data-feather="check" class="avatar-icon font-medium-3"></i>{{assignAllButtonTextSheet()}}</button>
                  </div>
                  <div class="col-12" *ngIf="selectedSheetData && selectedSheetData.sheetList[0]">
                    <div class="card card-transaction">
                      <div class="card-body">
                        <div class="transaction-item" *ngFor="let sheet of selectedSheetData.sheetList">
                          <div class="media">
                            <div class="avatar bg-light-primary rounded">
                              <div class="avatar-content">
                                <i data-feather="paperclip" class="avatar-icon font-medium-3"></i>
                              </div>
                            </div>
                            <div class="media-body">
                              <h6 class="transaction-title">{{ sheet.sheetName }}</h6>
                              <small>{{ sheet.sheetDescription }}</small>
                            </div>
                          </div>
                          <button class="btn font-weight-bolder btn-success" *ngIf="!decideActionSheet(sheet)" (click)="assignButtonClickSheet(true, sheet)">Assign</button>
                          <button class="btn font-weight-bolder btn-danger" *ngIf="decideActionSheet(sheet)" (click)="assignButtonClickSheet(false, sheet)">Unassign</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <!--  Sortable lists section end -->
      </div>
    </div>
  </div>
</ng-template>
<!-- / Modal -->

<!-- add form category Modal -->
<ng-template #userDetail let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="myModalLabel1">User Detail</h4>
    <button type="button" class="close" (click)="modalCloseFn()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" tabindex="0" ngbAutofocus>
    <div class="content-wrapper container-xxl p-0">
      <div class="content-body">
        <section class="app-user-view">
          <div class="col-12">
            <div class="card user-card">
              <div class="card-body">
                <div class="row">
                  <div class="col-xl-6 col-lg-12 d-flex flex-column justify-content-between border-container-lg">
                    <div class="user-avatar-section">
                      <div class="d-flex justify-content-start">
                        <div *ngIf="selectedUserData.profileImage; else customAvatar">
                          <img class="img-fluid rounded" [src]="'https://server.fincooper.in' + selectedUserData.profileImage" height="104" width="104" alt="User avatar" />
                        </div>
                        <ng-template #customAvatar>
                          <div class="mr-1 ml-0">
                            <div class="rounded p-3 bg-light-success">
                              <h2 class="m-0 text-success">
                                {{ selectedUserData.name | initials }}
                              </h2>
                            </div>
                          </div>
                        </ng-template>
                        <div class="d-flex flex-column ml-1">
                          <div class="user-info mb-1">
                            <h4 class="mb-0">{{ selectedUserData.name }}</h4>
                            <span class="card-text">{{ selectedUserData.email }}</span>
                          </div>
                          <!-- <div class="d-flex flex-wrap">
                            <a class="btn btn-primary" rippleEffect>Edit</a>
                            <button class="btn btn-outline-danger ml-1" rippleEffect>Delete</button>
                          </div> -->
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 mt-2 mt-xl-0 d-flex justify-content-between">
                    <div class="user-info-wrapper">
                      <div class="d-flex flex-wrap my-50">
                        <div class="user-info-title">
                          <i data-feather="git-branch" class="mr-1"></i>
                          <span class="card-text user-info-title font-weight-bold mb-0">Branch</span>
                        </div>
                        <p class="card-text mb-0">{{ selectedUserData.branch }}</p>
                      </div>
                      <div class="d-flex flex-wrap my-50">
                        <div class="user-info-title">
                          <i data-feather="mail" class="mr-1"></i>
                          <span class="card-text user-info-title font-weight-bold mb-0">Official Mail</span>
                        </div>
                        <p class="card-text mb-0">{{ selectedUserData.officialMail }}</p>
                      </div>
                      <div class="d-flex flex-wrap my-50">
                        <div class="user-info-title">
                          <i data-feather="calendar" class="mr-1"></i>
                          <span class="card-text user-info-title font-weight-bold mb-0">Joining Date</span>
                        </div>
                        <p class="card-text mb-0">{{ selectedUserData.joiningData }}</p>
                      </div>
                      <div class="d-flex flex-wrap my-50">
                        <div class="user-info-title">
                          <i data-feather="users" class="mr-1"></i>
                          <span class="card-text user-info-title font-weight-bold mb-0">Department</span>
                        </div>
                        <p class="card-text mb-0">{{ selectedUserData.department }}</p>
                      </div>
                    </div>
                    <div class="user-info-wrapper">
                      <div class="d-flex flex-wrap my-50">
                        <div class="user-info-title">
                          <i data-feather="briefcase" class="mr-1"></i>
                          <span class="card-text user-info-title font-weight-bold mb-0">Designation</span>
                        </div>
                        <p class="card-text mb-0">{{ selectedUserData.designation }}</p>
                      </div>
                      <div class="d-flex flex-wrap my-50">
                        <div class="user-info-title">
                          <i data-feather="phone" class="mr-1"></i>
                          <span class="card-text user-info-title font-weight-bold mb-0">Contact</span>
                        </div>
                        <p class="card-text mb-0">{{ selectedUserData.contact }}</p>
                      </div>
                      <div class="d-flex flex-wrap my-50">
                        <div class="user-info-title">
                          <i data-feather="user" class="mr-1"></i>
                          <span class="card-text user-info-title font-weight-bold mb-0">Employee Id</span>
                        </div>
                        <p class="card-text mb-0">{{ selectedUserData.employeeId }}</p>
                      </div>
                      <div class="d-flex flex-wrap my-50">
                        <div class="user-info-title">
                          <i data-feather="navigation" class="mr-1"></i>
                          <span class="card-text user-info-title font-weight-bold mb-0">Address</span>
                        </div>
                        <p class="card-text mb-0">{{ selectedUserData.address }}</p>
                      </div>
                    </div>
                  </div>
                  <!-- <div class="col-xl-6 col-lg-12 mt-2 mt-xl-0">
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</ng-template>
<!-- / Modal -->
