import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";

import { CoreCommonModule } from "@core/common.module";
import { DragulaModule } from 'ng2-dragula';


import { ContentHeaderModule } from "app/layout/components/content-header/content-header.module";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";

import { HomeComponent } from "./home.component";
import { RoleComponent } from "./role/role.component";
import { FormComponent } from "./form/form.component";
import { UserComponent } from "./user/user.component";
import { NgbCarousel, NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgSelectModule } from "@ng-select/ng-select";
import { FormsModule } from "@angular/forms";
import { CsvModule } from "@ctrl/ngx-csv";
import { SwiperModule } from "ngx-swiper-wrapper";
import { CoreCardModule } from "@core/components/core-card/core-card.module";
import { AuthGuard } from "app/auth/helpers";
import { Role } from "app/auth/models";
import { SheetComponent } from './sheet/sheet.component';

const routes = [
  {
    path: "home",
    component: HomeComponent,
    data: { animation: "home"  },
    canActivate:[AuthGuard]

  },
  {
    path: "role",
    component: RoleComponent,
    data: { animation: "role",roles: [Role.Admin,Role.FormAndRoleEditor,Role.UserAndRoleEditor,Role.RoleEditor]  },
    canActivate:[AuthGuard],
  },
  {
    path: "form",
    component: FormComponent,
    data: { animation: "form",roles: [Role.Admin,Role.FormAndRoleEditor,Role.FormAndUserEditor,Role.FormEditor] },
    canActivate:[AuthGuard]
  },
  {
    path: "user",
    component: UserComponent,
    data: { animation: "user",roles: [Role.Admin,Role.FormAndUserEditor,Role.UserAndRoleEditor,Role.UserEditor] },
    canActivate:[AuthGuard]

  },
  {
    path: "sheet",
    component: SheetComponent,
    data: { animation: "user",roles: [Role.Admin,Role.FormAndRoleEditor,Role.FormAndUserEditor,Role.FormEditor]},
    canActivate:[AuthGuard]

  },
];

@NgModule({
  declarations: [HomeComponent, RoleComponent, FormComponent, UserComponent, SheetComponent],
  imports: [RouterModule.forChild(routes), ContentHeaderModule, TranslateModule, CoreCommonModule, NgbModule, NgSelectModule, FormsModule, NgxDatatableModule, CsvModule,SwiperModule,CoreCardModule,DragulaModule.forRoot()],
  exports: [HomeComponent],
})
export class HomeModule {}
