import { Component, HostListener, OnInit, QueryList, ViewChild, ViewChildren } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbModal, NgbPopover } from "@ng-bootstrap/ng-bootstrap";
import { BackendServiceService } from "app/service/backend-service.service";
import { ToastrServiceService } from "app/service/toastrservice.service";

@Component({
  selector: "app-form",
  templateUrl: "./form.component.html",
  styleUrls: ["./form.component.scss"],
})
export class FormComponent implements OnInit {
  public formList: Array<any>;
  public formCategoryList: Array<any>;
  public tempFormList: any;
  public tempFormCategoryList: any;
  formCategoryForm: FormGroup;
  selectedFormCategoryId;
  leadForm: FormGroup;
  tempRoleList: any;
  roleList: any;
  isMobile = window.innerWidth < 576;
  selectFormCategoryData;
  selectFormData;

  @ViewChildren("formPopover") formPopovers: QueryList<NgbPopover>;
  formPopOverIndex: any;
  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.isMobile = window.innerWidth < 576; // Adjust the threshold as per your requirement
  }

  constructor(private backendService: BackendServiceService, private modalService: NgbModal, private fb: FormBuilder, private toster: ToastrServiceService) {
    this.formCategoryForm = this.fb.group({
      formCategoryName: ["", Validators.required],
      defaultRole: FormArray,
    });
    this.leadForm = this.fb.group({
      formCategoryId: ["", Validators.required],
      formName: ["", Validators.required],
      formDescription: ["", Validators.required],
      formLink: ["", Validators.required],
    });
  }

  modalOpen(modalBasic, size) {
    this.modalService.open(modalBasic, {
      centered: true,
      backdrop: "static",
      keyboard: true,
      size: size,
    });
  }

  ngOnInit(): void {
    this.getFormCategoryList();
    this.getRoleList();
  }

  addNewFormCategory() {
    const bodyData = this.formCategoryForm.value;
    this.backendService.addFormCategory(bodyData).subscribe((res) => {
      this.getFormCategoryList();
    });
  }
  updateFormCategory() {
    const bodyData = this.formCategoryForm.value;
    this.backendService.updateFormCategory(bodyData, this.selectFormCategoryData.formCategoryId).subscribe((res) => {
      if (res.status) {
        this.getFormCategoryList();
        this.toster.showSuccess(res.message, "Success");
      } else {
        this.toster.showError(res.message, "Error");
      }
      this.selectFormCategoryData = null;
      this.formCategoryForm.reset();
      this.modalService.dismissAll();
    });
  }
  changefn(event){
    
  }
  addNewForm() {
    const bodyData = this.leadForm.value;
    this.backendService.addForm(bodyData).subscribe((res) => {
      this.getFormCategoryList();
    });
  }

  updateForm() {
    const bodyData = this.leadForm.value;
    this.backendService.updateForm(bodyData,  this.selectFormData.formId).subscribe((res) => {
      if (res.status) {
        this.getFormCategoryList();
        this.toster.showSuccess(res.message, "Success");
      } else {
        this.toster.showError(res.message, "Error");
      }
      this.selectFormData = null;
      this.leadForm.reset();
      this.modalService.dismissAll();
    });
  }

  getFormCategoryList() {
    this.backendService.getFormCategoryList().subscribe((res) => {
      this.formCategoryList = res.items;
      this.tempFormCategoryList = this.formCategoryList;
    });
  }

  submitCategoryForm(popover) {
    if (this.formCategoryForm.valid) {
      this.addNewFormCategory();
      popover.close();
      this.formCategoryForm.reset();
    } else {
      return;
    }
  }

  submitUpdateCategoryForm() {
    if (this.formCategoryForm.valid) {
      this.updateFormCategory();
    } else {
      return;
    }
  }

  submitForm(index) {
    this.leadForm.controls.formCategoryId.setValue(this.selectedFormCategoryId);

    if (this.leadForm.valid) {
      this.addNewForm();
      this.formPopovers.toArray()[this.formPopOverIndex].close();
      this.leadForm.reset();
    } else {
      return;
    }
  }

  submitUpdateForm() {
    if (this.leadForm.valid) {
      this.updateForm();
    } else {
      return;
    }
  }

  editFormCategoryOption(mRef, formCategoryData) {
    this.selectFormCategoryData = formCategoryData;
    this.formCategoryForm.patchValue({
      formCategoryName: this.selectFormCategoryData.formCategoryName,
      defaultRole: this.selectFormCategoryData.defaultRole,
    });
    this.modalOpen(mRef, "lg");
  }

  editFormOption(mRef, formData) {
    this.selectFormData = formData;
    this.leadForm.patchValue({
      formCategoryId: this.selectFormData.formCategoryId,
      formName: this.selectFormData.formName,
      formDescription: this.selectFormData.formDescription,
      formLink: this.selectFormData.formLink,
    });
    this.modalOpen(mRef, "sm");
  }

  getRoleList() {
    this.backendService.getRoleList().subscribe((res) => {
      this.roleList = res.items;
      this.tempRoleList = this.roleList;
    });
  }

  recordShown(formCategoryId, index) {
    this.formPopOverIndex = index;

    this.selectedFormCategoryId = formCategoryId;
  }
  recordHidden() {
    this.selectedFormCategoryId = null;
  }

  closePopOver() {
    this.formPopovers.toArray()[this.formPopOverIndex].close();
  }

  modalCloseFn() {
    this.formCategoryForm.reset();
    this.leadForm.reset();
    this.modalService.dismissAll();
    this.selectFormCategoryData = null;
    this.selectFormData = null;
  }
}
