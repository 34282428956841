import { Role } from './role';

export class User {
  userId: number;
  userName: string;
  name: string;
  profileImage: string;
  role: string;
  token:string;
}
