<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
      <div class="d-flex justify-content-end mb-2" [ngClass]="{ 'floating-button': isMobile }">
        <button class="btn btn-relief-primary" [ngClass]="{ 'mr-2': !isMobile }" [ngbPopover]="popContent" #categoryPopover="ngbPopover" [autoClose]="false" [popoverTitle]="popTitle" placement="auto" animation="true" rippleEffect><i data-feather="plus" class="mr-0 mr-sm-1"></i><span class="d-none d-sm-inline-block">Add New Sheet Category</span></button>
      </div>
      <div class="row match-height">
        <div class="col-lg-4 col-md-6 col-12" *ngFor="let sheetCategory of tempSheetCategoryList; let i = index">
          <div class="card card-transaction">
            <div class="card-header">
              <h4 class="card-title">{{ sheetCategory.sheetCategoryName }}</h4>
              <div ngbDropdown container="body">
                <a ngbDropdownToggle href="javascript:void(0);" class="hide-arrow" id="dropdownBrowserState" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <i data-feather="more-vertical" class="text-primary cursor-pointer mr-50"></i>
                </a>
                <div ngbDropdownMenu class="dropdown-menu-right" aria-labelledby="dropdownBrowserState">
                  <a href="javascript:void(0)" (click)="editSheetCategoryOption(editSheetCategoryModel, sheetCategory)" ngbDropdownItem class="d-flex align-items-center"><i data-feather="edit" class="mr-50"></i> Edit</a>
                  <!-- <a href="javascript:void(0)" ngbDropdownItem class="d-flex align-items-center"><i data-feather="trash-2" class="mr-50"></i> Delete</a> -->
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="transaction-item" *ngFor="let sheet of sheetCategory.sheetList">
                <div class="media">
                  <div class="avatar bg-light-primary rounded">
                    <div class="avatar-content">
                      <i data-feather="grid" class="avatar-icon font-medium-3"></i>
                    </div>
                  </div>
                  <div class="media-body">
                    <h6 class="transaction-title">{{ sheet.sheetName }}</h6>
                    <small>{{ sheet.sheetDescription }}</small>
                  </div>
                </div>
                <div ngbDropdown container="body">
                  <a ngbDropdownToggle href="javascript:void(0);" class="hide-arrow" id="dropdownBrowserState" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i data-feather="more-vertical" class="text-primary cursor-pointer mr-50"></i>
                  </a>
                  <div ngbDropdownMenu class="dropdown-menu-right" aria-labelledby="dropdownBrowserState">
                    <a href="javascript:void(0)" (click)="editSheetOption(editSheetModel, sheet)" ngbDropdownItem class="d-flex align-items-center"><i data-feather="edit" class="mr-50"></i> Edit</a>
                    <!-- <a href="javascript:void(0)" ngbDropdownItem class="d-flex align-items-center"><i data-feather="trash-2" class="mr-50"></i> Delete</a> -->
                  </div>
                </div>
                <!-- <button class="btn font-weight-bolder btn-primary" >Update</button> -->
              </div>
            </div>
            <button class="btn font-weight-bolder btn-primary" [ngbPopover]="popContentForm" #formPopover="ngbPopover" [autoClose]="false" [popoverTitle]="popTitleForm" placement="auto" animation="true" (shown)="recordShown(sheetCategory.sheetCategoryId, i)" (hidden)="recordHidden()" rippleEffect><i data-feather="plus" class="mr-0 mr-sm-1"></i><span class="d-none d-sm-inline-block">Add New Sheet</span></button>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <ng-template #popContent>
    <form [formGroup]="sheetCategoryForm" class="form form-horizontal">
      <div class="row">
        <div class="col-12">
          <div class="form-group row">
            <div class="col-sm-12 col-form-label">
              <label>Sheet Category Name</label>
            </div>
            <div class="col-sm-12">
              <div class="input-group input-group-merge">
                <div class="input-group-prepend">
                  <span class="input-group-text"><span [data-feather]="'user'"></span></span>
                </div>
                <input type="text" id="fname-icon" formControlName="sheetCategoryName" class="form-control" name="fname-icon" placeholder="Sheet Category Name" />
              </div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="form-group row">
            <div class="col-sm-12 col-form-label">
              <label>Permitted Roles</label>
            </div>
            <div class="col-sm-12">
              <ng-select [items]="tempRoleList" [addTag]="selectAddTagMethod" [hideSelected]="true" multiple="true" bindLabel="roleName" bindValue="roleId" formControlName="defaultRole">
                <ng-template ng-label-tmp let-item="item" let-clear="clear">
                  <span class="ng-value-label"> {{ item.roleName }}</span>
                  <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                </ng-template>
              </ng-select>
            </div>
          </div>
        </div>
        <div class="col-sm-9 offset-sm-3">
          <button type="submit" (click)="submitCategorySheet(categoryPopover)" rippleEffect class="btn btn-primary mr-1">Submit</button>
        </div>
      </div>
    </form>
  </ng-template>
  <ng-template #popTitle>
    <div class="d-flex justify-content-between align-items-center">
      <b>Add Category</b>
      <button class="btn btn-flat-danger" (click)="categoryPopover.close()">
        <strong class="font-medium-3" [data-feather]="'x-circle'"></strong>
      </button>
    </div>
  </ng-template>
  
  <ng-template #popContentForm>
    <form [formGroup]="leadSheet" class="form form-horizontal">
      <div class="row">
        <div class="col-12">
          <div class="form-group row">
            <div class="col-sm-12 col-form-label">
              <label>Sheet Name</label>
            </div>
            <div class="col-sm-12">
              <div class="input-group input-group-merge">
                <div class="input-group-prepend">
                  <span class="input-group-text"><span [data-feather]="'user'"></span></span>
                </div>
                <input type="text" id="fname-icon" formControlName="sheetName" class="form-control" name="fname-icon" placeholder="Sheet Name" />
              </div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="form-group row">
            <div class="col-sm-12 col-form-label">
              <label>Sheet Description</label>
            </div>
            <div class="col-sm-12">
              <div class="input-group input-group-merge">
                <div class="input-group-prepend">
                  <span class="input-group-text"><span [data-feather]="'user'"></span></span>
                </div>
                <input type="text" id="fname-icon" formControlName="sheetDescription" class="form-control" name="fname-icon" placeholder="Sheet Description" />
              </div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="form-group row">
            <div class="col-sm-12 col-form-label">
              <label>Sheet Link</label>
            </div>
            <div class="col-sm-12">
              <div class="input-group input-group-merge">
                <div class="input-group-prepend">
                  <span class="input-group-text"><span [data-feather]="'user'"></span></span>
                </div>
                <input type="text" id="fname-icon" formControlName="sheetLink" class="form-control" name="fname-icon" placeholder="example.com" />
              </div>
            </div>
          </div>
        </div>
  
        <div class="col-sm-9 offset-sm-3">
          <button type="submit" (click)="submitSheet(newFormPopover)" rippleEffect class="btn btn-primary mr-1">Submit</button>
        </div>
      </div>
    </form>
  </ng-template>
  <ng-template #popTitleForm>
    <div class="d-flex justify-content-between align-items-center">
      <b>Add Sheet</b>
      <button class="btn btn-flat-danger" (click)="closePopOver()">
        <strong class="font-medium-3" [data-feather]="'x-circle'"></strong>
      </button>
    </div>
  </ng-template>
  
  <!-- add sheet category Modal -->
  <ng-template #editSheetCategoryModel let-user="user" let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="myModalLabel1">Edit Sheet Category</h4>
      <button type="button" class="close" (click)="modalCloseFn()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
      <div class="content-wrapper container-xxl p-0">
        <div class="content-body">
          <form [formGroup]="sheetCategoryForm" class="form form-horizontal">
            <div class="row">
              <div class="col-12">
                <div class="form-group row">
                  <div class="col-sm-12 col-form-label">
                    <label>Sheet Category Name</label>
                  </div>
                  <div class="col-sm-12">
                    <div class="input-group input-group-merge">
                      <div class="input-group-prepend">
                        <span class="input-group-text"><span [data-feather]="'user'"></span></span>
                      </div>
                      <input type="text" id="fname-icon" formControlName="sheetCategoryName" class="form-control" name="fname-icon" placeholder="Sheet Category Name" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="form-group row">
                  <div class="col-sm-12 col-form-label">
                    <label>Permitted Roles</label>
                  </div>
                  <div class="col-sm-12">
                    <ng-select [items]="tempRoleList" [addTag]="selectAddTagMethod" [hideSelected]="true" multiple="true" bindLabel="roleName" bindValue="roleId" formControlName="defaultRole">
                      <ng-template ng-label-tmp let-item="item" let-clear="clear">
                        <span class="ng-value-label"> {{ item.roleName }}</span>
                        <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                      </ng-template>
                    </ng-select>
                  </div>
                </div>
              </div>
              <div class="col-sm-9 offset-sm-3">
                <button type="submit" (click)="submitUpdateCategorySheet(sheetCategoryId)" rippleEffect class="btn btn-primary mr-1">Submit</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </ng-template>
  <!-- / Modal -->
  
  <!-- add form category Modal -->
  <ng-template #editSheetModel let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="myModalLabel1">Edit Sheet Category</h4>
      <button type="button" class="close" (click)="modalCloseFn()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
      <div class="content-wrapper container-xxl p-0">
        <div class="content-body">
          <form [formGroup]="leadSheet" class="form form-horizontal">
            <div class="row">
              <div class="col-12">
                <div class="form-group row">
                  <div class="col-sm-12 col-form-label">
                    <label>Sheet Category Roles</label>
                  </div>
                  <div class="col-sm-12">
                    <ng-select [items]="tempSheetCategoryList" [addTag]="selectAddTagMethod" [hideSelected]="true" bindLabel="formCategoryName" bindValue="sheetCategoryId" formControlName="sheetCategoryId">
                      <ng-template ng-label-tmp let-item="item" let-clear="clear">
                        <span class="ng-value-label"> {{ item.sheetCategoryName }}</span>
                        <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                      </ng-template>
                    </ng-select>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="form-group row">
                  <div class="col-sm-12 col-form-label">
                    <label>Sheet Name</label>
                  </div>
                  <div class="col-sm-12">
                    <div class="input-group input-group-merge">
                      <div class="input-group-prepend">
                        <span class="input-group-text"><span [data-feather]="'user'"></span></span>
                      </div>
                      <input type="text" id="fname-icon" formControlName="sheetName" class="form-control" name="fname-icon" placeholder="Sheet Name" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="form-group row">
                  <div class="col-sm-12 col-form-label">
                    <label>Sheet Description</label>
                  </div>
                  <div class="col-sm-12">
                    <div class="input-group input-group-merge">
                      <div class="input-group-prepend">
                        <span class="input-group-text"><span [data-feather]="'user'"></span></span>
                      </div>
                      <input type="text" id="fname-icon" formControlName="sheetDescription" class="form-control" name="fname-icon" placeholder="Form Description" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="form-group row">
                  <div class="col-sm-12 col-form-label">
                    <label>Sheet Link</label>
                  </div>
                  <div class="col-sm-12">
                    <div class="input-group input-group-merge">
                      <div class="input-group-prepend">
                        <span class="input-group-text"><span [data-feather]="'user'"></span></span>
                      </div>
                      <input type="text" id="fname-icon" formControlName="sheetLink" class="form-control" name="fname-icon" placeholder="example.com" />
                    </div>
                  </div>
                </div>
              </div>
  
              <div class="col-12 d-flex justify-content-center">
                <button type="submit" (click)="submitUpdateSheet()" rippleEffect class="btn btn-primary mr-1">Submit</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </ng-template>
  <!-- / Modal -->
  