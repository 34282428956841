export enum Role {
  Admin = 'Admin',
  FormAndRoleEditor = 'FormAndRoleEditor',
  FormAndUserEditor = 'FormAndUserEditor',
  UserAndRoleEditor = 'UserAndRoleEditor',
  FormEditor = 'FormEditor',
  RoleEditor = 'RoleEditor',
  UserEditor = 'UserEditor',
  default="Default"
}
