import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from 'environments/environment';
import { User, Role } from 'app/auth/models';
import { ToastrService } from 'ngx-toastr';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  //public
  public currentUser: Observable<User>;

  //private
  private currentUserSubject: BehaviorSubject<User>;

  /**
   *
   * @param {HttpClient} _http
   * @param {ToastrService} _toastrService
   */
  constructor(private _http: HttpClient, private _toastrService: ToastrService) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('user')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  // getter: currentUserValue
  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  /**
   *  Confirms if user is admin
   */
  get isAdmin() {
    return this.currentUser && this.currentUserSubject.value.role === Role.Admin;
  }

  /**
   *  Confirms if user is client
   */
  get isClient() {
    return this.currentUser && this.currentUserSubject.value.role === Role.UserEditor;
  }

  /**
   * User login
   *
   * @param userName
   * @param password
   * @returns user
   */
  login(userName: string, password: string) {
    return this._http
      .post<any>(`${environment.apiUrl}/v1/user/login`, { userName, password })
      .pipe(
        map(res => {
          if (res.status) {
            if (res.items && res.items.token) {
              // store user details and jwt token in local storage to keep user logged in between page refreshes
              localStorage.setItem('user', JSON.stringify(res.items));
  
              // Display welcome toast!
              this._toastrService.success(
                'You have successfully logged in as an ' +
                res.items.role +' 🎉',
                '👋 Welcome, ' + res.items.name + '!',
                { closeButton: true }
              );
            
  
              // notify
              this.currentUserSubject.next(res.items);
            }
            
          }else{
            this._toastrService.error(res.message,"Fail")
          }
          // login successful if there's a jwt token in the response

          return res;
        })
      );
  }

  /**
   * User logout
   *
   */
  logout() {
    
    // remove user from local storage to log user out
    localStorage.removeItem('user');
    // notify
    this.currentUserSubject.next(null);
  }
}
